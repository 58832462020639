import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './DashboardPage.scss';

export function DashboardPage() {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const goToConsole = () => {
    navigate('/console');
  };

  return (
    <div className="dashboard-page">
      <h2>Welcome to Assist Engine AI{user ? `, ${user.email}` : ''}</h2>
      <button onClick={goToConsole}>Launch Voice Agent Console</button>
      <button onClick={handleSignOut}>Sign Out</button>
    </div>
  );
}