// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/ProfilePage.scss */
.profile-page {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}
.profile-page h2 {
  margin-bottom: 2rem;
  color: #333;
}
.profile-page label {
  display: block;
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
}
.profile-page input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
}
.profile-page input:focus {
  border-color: #0099ff;
  outline: none;
}
.profile-page button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  background-color: #0099ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.profile-page button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.profile-page button:hover:not(:disabled) {
  background-color: #007acc;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage.scss"],"names":[],"mappings":"AAAA,+BAAA;AAEA;EACI,gBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AAEI;EACE,mBAAA;EACA,WAAA;AAAN;AAGI;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,WAAA;AADN;AAII;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;AAFN;AAIM;EACE,qBAAA;EACA,aAAA;AAFR;AAMI;EACE,qBAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAJN;AAMM;EACE,sBAAA;EACA,mBAAA;AAJR;AAOM;EACE,yBAAA;AALR","sourcesContent":["/* src/pages/ProfilePage.scss */\n\n.profile-page {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 2rem;\n    text-align: center;\n  \n    h2 {\n      margin-bottom: 2rem;\n      color: #333;\n    }\n  \n    label {\n      display: block;\n      text-align: left;\n      font-weight: bold;\n      margin-bottom: 0.5rem;\n      color: #555;\n    }\n  \n    input {\n      width: 100%;\n      padding: 0.75rem;\n      font-size: 1rem;\n      border: 1px solid #ccc;\n      border-radius: 4px;\n      margin-bottom: 1.5rem;\n      box-sizing: border-box;\n  \n      &:focus {\n        border-color: #0099ff;\n        outline: none;\n      }\n    }\n  \n    button {\n      padding: 0.75rem 2rem;\n      font-size: 1rem;\n      background-color: #0099ff;\n      color: #fff;\n      border: none;\n      border-radius: 4px;\n      cursor: pointer;\n  \n      &:disabled {\n        background-color: #ccc;\n        cursor: not-allowed;\n      }\n  \n      &:hover:not(:disabled) {\n        background-color: #007acc;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
