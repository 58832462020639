import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from '@mui/material';
import { Line, Pie } from 'react-chartjs-2';
import './AnalyticsPage.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface AnalyticsData {
  timestamp: string;
  event: {
    type: string;
    [key: string]: any;
  };
}

export function AnalyticsPage() {
  const { session, user } = useAuth();
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAnalytics = async () => {
    if (!session || !user) {
      console.error('User not authenticated');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('/api/analytics', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${session.access_token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching analytics: ${response.statusText}`);
      }

      const result = await response.json();
      setAnalyticsData(result.data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, user]);

  // Prepare data for Line Chart
  const lineChartData = {
    labels: analyticsData.map((item) =>
      new Date(item.timestamp).toLocaleDateString()
    ),
    datasets: [
      {
        label: 'Interactions Over Time',
        data: analyticsData.map(() => 1), // Assuming each item represents an interaction
        fill: false,
        borderColor: '#3f51b5',
        tension: 0.1,
      },
    ],
  };

  // Aggregate data for Pie Chart (example categories)
  const categoryCounts: { [key: string]: number } = {};
  analyticsData.forEach((item) => {
    const category = item.event.type || 'Unknown';
    categoryCounts[category] = (categoryCounts[category] || 0) + 1;
  });

  const pieChartData = {
    labels: Object.keys(categoryCounts),
    datasets: [
      {
        label: 'Event Types',
        data: Object.values(categoryCounts),
        backgroundColor: [
          '#3f51b5',
          '#f50057',
          '#ff9800',
          '#4caf50',
          '#9c27b0',
          '#00acc1',
          '#ff4081',
          '#cddc39',
        ],
        hoverOffset: 4,
      },
    ],
  };

  if (loading) {
    return (
      <Container className="analytics-page">
        <Typography variant="h4" gutterBottom>
          Your Analytics
        </Typography>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      className="analytics-page"
      sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}
    >
      <Typography variant="h4" gutterBottom>
        Your Analytics
      </Typography>
      {analyticsData.length === 0 ? (
        <Typography>No analytics data available.</Typography>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: '1rem' }}>
              <Typography variant="h6" gutterBottom>
                Interactions Over Time
              </Typography>
              <Line data={lineChartData} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: '1rem' }}>
              <Typography variant="h6" gutterBottom>
                Event Types Distribution
              </Typography>
              <Pie data={pieChartData} />
            </Paper>
          </Grid>
          {/* Add more charts or metrics as needed */}
        </Grid>
      )}
    </Container>
  );
}