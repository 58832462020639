import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import './ProfilePage.scss';

interface Profile {
  id: string;
  username: string;
}

export function ProfilePage() {
  const { user } = useAuth();
  const [username, setUsername] = useState('');
  const [updating, setUpdating] = useState(false);

  const fetchProfile = async () => {
    if (!user) return;

    // Fix for type arguments
    const { data, error } = await supabase
      .from('profiles')
      .select('username')
      .eq('id', user.id)
      .single();

    if (error) {
      console.error('Error fetching profile:', error.message);
    } else if (data) {
      setUsername(data.username);
    }
  };

  const updateProfile = async () => {
    if (!user) return;

    setUpdating(true);
    const { error } = await supabase
      .from('profiles')
      .upsert({ id: user.id, username }, { onConflict: 'id' });

    setUpdating(false);

    if (error) {
      alert('Error updating profile');
      console.error('Error updating profile:', error.message);
    } else {
      alert('Profile updated successfully');
    }
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <div className="profile-page">
      <h2>Your Profile</h2>
      <label htmlFor="username">Username:</label>
      <input
        id="username"
        type="text"
        placeholder="Enter your username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <button onClick={updateProfile} disabled={updating}>
        {updating ? 'Updating...' : 'Update Profile'}
      </button>
    </div>
  );
}