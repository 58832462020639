// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-callback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
}
.auth-callback h2 {
  margin-bottom: 1rem;
}
.auth-callback p {
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/pages/AuthCallbackPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;AACJ;AACI;EACE,mBAAA;AACN;AAEI;EACE,WAAA;AAAN","sourcesContent":[".auth-callback {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    padding: 20px;\n    text-align: center;\n  \n    h2 {\n      margin-bottom: 1rem;\n    }\n  \n    p {\n      color: #666;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
