import { useEffect, useRef, useCallback, useState, ReactNode, ButtonHTMLAttributes } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../utils/supabaseClient';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavStreamPlayer, WavRecorder } from '../lib/wavtools';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';
import { X, Zap, ArrowUp, ArrowDown } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';
import { Map } from '../components/Map';
import './ConsolePage.scss';
import { Session, User } from '@supabase/supabase-js';
import { 
  TurnDetectionType, 
  SessionConfig, 
  RealtimeEvent, 
  ConversationItem,
  SessionResourceType,
  VoiceType,
  AudioTranscriptionType,
} from '../types';
import type { TurnDetectionServerVadType } from '@openai/realtime-api-beta/dist/lib/client';

// Constants
const LOCAL_RELAY_SERVER_URL = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';


// Helper function to convert Int16Array to Float32Array
const convertToFloat32Array = (int16Array: Int16Array): Float32Array => {
  const float32Array = new Float32Array(int16Array.length);
  for (let i = 0; i < int16Array.length; i++) {
    // Normalize to [-1, 1] range
    float32Array[i] = int16Array[i] / 32768.0;
  }
  return float32Array;
};

// Types
interface Coordinates {
  lat: number;
  lng: number;
  location?: string;
  temperature?: {
    value: number;
    units: string;
  };
  wind_speed?: {
    value: number;
    units: string;
  };
}


export function ConsolePage() {
  const { user, session } = useAuth();
  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef(new RealtimeClient({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    ...(LOCAL_RELAY_SERVER_URL ? { relayUrl: LOCAL_RELAY_SERVER_URL } : {})
  }));

  // UI refs
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const startTimeRef = useRef<string>(new Date().toISOString());

  // State
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{ [key: string]: boolean }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const [coords, setCoords] = useState<Coordinates>({
    lat: 37.775593,
    lng: -122.418137,
  });
  const [marker, setMarker] = useState<Coordinates | null>(null);

  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) s = '0' + s;
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
  
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems() as ItemType[]);
  
    try {
      // Initialize audio components first
      await wavRecorder.begin();
      
      // Connect and ensure audio context is running
      const audioContext = await wavStreamPlayer.connect();
      if (audioContext?.state !== 'running') {
        await audioContext.resume();
      }
  
      // Connect to API
      await client.connect();
  
      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => {
          const float32Audio = convertToFloat32Array(data.mono);
          client.appendInputAudio(float32Audio);
        });
      }
    } catch (error) {
      console.error('Error connecting:', error);
      setIsConnected(false);
    }
  }, []);

  useEffect(() => {
    const resumeAudioContext = async () => {
      try {
        const wavStreamPlayer = wavStreamPlayerRef.current;
        const audioContext = await wavStreamPlayer.connect();
        
        if (audioContext?.state !== 'running') {
          await audioContext.resume();
        }
      } catch (error) {
        console.error('Error resuming audio context:', error);
      }
    };
  
    // Add click handler to document
    document.addEventListener('click', resumeAudioContext);
    
    return () => {
      document.removeEventListener('click', resumeAudioContext);
    };
  }, []);


  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});
    setCoords({
      lat: 37.775593,
      lng: -122.418137,
    });
    setMarker(null);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  const startRecording = useCallback(async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    
    await wavRecorder.record((data) => {
      const float32Audio = convertToFloat32Array(data.mono);
      client.appendInputAudio(float32Audio);
    });
  }, []);

  const stopRecording = useCallback(async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  }, []);
  const changeTurnEndType = useCallback(async (enabled: boolean, value: 'none' | 'server_vad') => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    
    client.updateSession({
      turn_detection: value === 'server_vad' ? { type: 'server_vad' } : null
    });
    
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => {
        const float32Audio = convertToFloat32Array(data.mono);
        client.appendInputAudio(float32Audio);
      });
    }
    
    setCanPushToTalk(value === 'none');
  }, []);


  // Auto-scroll event logs effect
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  // Auto-scroll conversation logs effect
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  // Canvas visualization effect
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (!isLoaded) return;

      if (clientCanvas) {
        if (!clientCanvas.width || !clientCanvas.height) {
          clientCanvas.width = clientCanvas.offsetWidth;
          clientCanvas.height = clientCanvas.offsetHeight;
        }
        clientCtx = clientCtx || clientCanvas.getContext('2d');
        if (clientCtx) {
          clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
          const result = wavRecorder.recording
            ? wavRecorder.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(
            clientCanvas,
            clientCtx,
            result.values,
            '#0099ff',
            10,
            0,
            8
          );
        }
      }

      if (serverCanvas) {
        if (!serverCanvas.width || !serverCanvas.height) {
          serverCanvas.width = serverCanvas.offsetWidth;
          serverCanvas.height = serverCanvas.offsetHeight;
        }
        serverCtx = serverCtx || serverCanvas.getContext('2d');
        if (serverCtx) {
          serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
          const result = wavStreamPlayer.analyser
            ? wavStreamPlayer.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(
            serverCanvas,
            serverCtx,
            result.values,
            '#009900',
            10,
            0,
            8
          );
        }
      }

      window.requestAnimationFrame(render);
    };

    render();
    return () => {
      isLoaded = false;
    };
  }, []);

  // Setup effect
  useEffect(() => {
    const client = clientRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const userName = session?.user?.email || 'User';
  
    // Define the config with explicit typing
    const config = {
      instructions: instructions(userName),
      voice: 'alloy' as const,
      turn_detection: { type: 'server_vad' as const },
      input_audio_transcription: { 
        model: 'whisper-1' as const
      }
    };
  
    client.updateSession(config);


    // Connect to Realtime API
    const connectClient = async () => {
      try {
        await client.connect();
      } catch (error) {
        console.error('Failed to connect to Realtime API:', error);
      }
    };

    connectClient();


    // Add tools
    client.addTool(
      {
        name: 'set_memory',
        description: 'Saves important data about the user into memory.',
        parameters: {
          type: 'object',
          properties: {
            key: {
              type: 'string',
              description: 'The key of the memory value. Always use lowercase and underscores, no other characters.',
            },
            value: {
              type: 'string',
              description: 'Value can be anything represented as a string',
            },
          },
          required: ['key', 'value'],
        },
      },
      async ({ key, value }: { [key: string]: any }) => {
        setMemoryKv((prev) => ({ ...prev, [key]: value }));
        return { ok: true };
      }
    );

    client.addTool(
      {
        name: 'get_weather',
        description: 'Retrieves the weather for a given lat, lng coordinate pair. Specify a label for the location.',
        parameters: {
          type: 'object',
          properties: {
            lat: { type: 'number', description: 'Latitude' },
            lng: { type: 'number', description: 'Longitude' },
            location: { type: 'string', description: 'Name of the location' },
          },
          required: ['lat', 'lng', 'location'],
        },
      },
      async ({ lat, lng, location }: { [key: string]: any }) => {
        setMarker({ lat, lng, location });
        setCoords({ lat, lng, location });
        
        const result = await fetch(
          `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current_weather=true`
        );
        const json = await result.json();
        
        const temperature = {
          value: json.current_weather.temperature as number,
          units: '°C',
        };
        const wind_speed = {
          value: json.current_weather.windspeed as number,
          units: 'km/h',
        };
        
        setMarker({ lat, lng, location, temperature, wind_speed });
        return json;
      }
    );

    // Event handlers
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((prev) => {
        const lastEvent = prev[prev.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          const updatedLastEvent = { ...lastEvent };
          updatedLastEvent.count = (updatedLastEvent.count || 0) + 1;
          return [...prev.slice(0, -1), updatedLastEvent];
        }
        return [...prev, realtimeEvent];
      });
    });

    client.on('error', (event: any) => console.error(event));

    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      const wavStreamPlayer = wavStreamPlayerRef.current;
      
      if (delta?.audio) {
        try {
          // Ensure audio data is in the correct format (Int16Array)
          let audioData: Int16Array;
          
          if (delta.audio instanceof Int16Array) {
            audioData = delta.audio;
          } else if (delta.audio instanceof ArrayBuffer) {
            audioData = new Int16Array(delta.audio);
          } else if (delta.audio.buffer) {
            audioData = new Int16Array(delta.audio.buffer);
          } else {
            throw new Error('Invalid audio data format');
          }
          
          // Connect if needed and ensure context is running
          const audioContext = await wavStreamPlayer.connect();
          if (audioContext?.state !== 'running') {
            await audioContext.resume();
          }
          
          // Add audio to stream player
          wavStreamPlayer.add16BitPCM(audioData, item.id);
          
        } catch (error) {
          console.error('Error processing audio:', error);
        }
      }
    
      // Handle completed items with audio
      if (item.status === 'completed' && item.formatted?.audio?.length) {
        try {
          let audioBuffer: ArrayBuffer;
          
          if (item.formatted.audio instanceof ArrayBuffer) {
            audioBuffer = item.formatted.audio;
          } else if (item.formatted.audio.buffer) {
            audioBuffer = item.formatted.audio.buffer;
          } else {
            throw new Error('Invalid audio format in completed item');
          }
          
          const wavFile = await WavRecorder.decode(audioBuffer, 24000);
          item.formatted.file = wavFile;
        } catch (error) {
          console.error('Error decoding completed audio:', error);
        }
      }    


      // Save conversation to Supabase
      if (session?.user?.id) {
        try {
          const { error } = await supabase.from('conversations').insert([
            {
              user_id: session.user.id,
              content: JSON.stringify(items),
              created_at: new Date().toISOString(),
            },
          ]);
          if (error) throw error;
        } catch (error) {
          console.error('Error saving conversation:', error);
        }
      }
    
      setItems(items as ItemType[]);
    });
  

  // Initial items setup
  setItems(client.conversation.getItems() as ItemType[]);


    return () => {
      client.reset();
    };
  }, [session]);

  // Early return if not authenticated
  if (!user) {
    return <div>Please log in to access the console.</div>;
  }

  return (
    <div data-component="ConsolePage">
      <div className="content-top">
        <div className="content-title">
          <img src="/openai-logomark.svg" alt="OpenAI Logo" />
          <span>Assist Engine AI</span>
        </div>
      </div>
      
      <div className="content-main">
        <div className="content-logs">
          <div className="content-block events">
          <div className="visualization">
              <div className="visualization-entry client">
                <canvas ref={clientCanvasRef} />
              </div>
              <div className="visualization-entry server">
                <canvas ref={serverCanvasRef} />
              </div>
            </div>
            <div className="content-block-title">Events</div>
            <div className="content-block-body" ref={eventsScrollRef}>
              {!realtimeEvents.length && `Awaiting connection...`}
              {realtimeEvents.map((realtimeEvent, i) => {
                const count = realtimeEvent.count;
                const event = { ...realtimeEvent.event };
                if (event.type === 'input_audio_buffer.append') {
                  event.audio = `[trimmed: ${event.audio.length} bytes]`;
                } else if (event.type === 'response.audio.delta') {
                  event.delta = `[trimmed: ${event.delta.length} bytes]`;
                }
                return (
                  <div className="event" key={`${event.event_id}-${i}`}>
                    <div className="event-timestamp">
                      {formatTime(realtimeEvent.time)}
                    </div>
                    <div className="event-details">
                      <div
                        className="event-summary"
                        onClick={() => {
                          const id = `${event.event_id}-${i}`;
                          setExpandedEvents(prev => {
                            const expanded = { ...prev };
                            if (expanded[id]) {
                              delete expanded[id];
                            } else {
                              expanded[id] = true;
                            }
                            return expanded;
                          });
                        }}
                      >
                        <div
                          className={`event-source ${
                            event.type === 'error' ? 'error' : realtimeEvent.source
                          }`}
                        >
                          {realtimeEvent.source === 'client' ? (
                            <ArrowUp />
                          ) : (
                            <ArrowDown />
                          )}
                          <span>
                            {event.type === 'error'
                              ? 'error!'
                              : realtimeEvent.source}
                          </span>
                        </div>
                        <div className="event-type">
                          {event.type}
                          {count && ` (${count})`}
                        </div>
                      </div>
                      {!!expandedEvents[`${event.event_id}-${i}`] && (
                        <div className="event-payload">
                          {JSON.stringify(event, null, 2)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="content-block conversation">
            <div className="content-block-title">Conversation</div>
            <div className="content-block-body" data-conversation-content>
              {!items.length && `Awaiting connection...`}
              {items.map((conversationItem) => (
                <div className="conversation-item" key={conversationItem.id}>
                  <div className={`speaker ${conversationItem.role || ''}`}>
                    <div>
                      {(conversationItem.role || conversationItem.type).replaceAll('_', ' ')}
                    </div>
                    <div
                      className="close"
                      onClick={() => deleteConversationItem(conversationItem.id)}
                    >
                      <X />
                    </div>
                  </div>
                  <div className={`speaker-content`}>
                    {conversationItem.type === 'function_call_output' && (
                      <div>{conversationItem.formatted.output}</div>
                    )}
                    {!!conversationItem.formatted.tool && (
                      <div>
                        {conversationItem.formatted.tool.name}(
                        {conversationItem.formatted.tool.arguments})
                      </div>
                    )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === 'user' && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            (conversationItem.formatted.audio?.length
                              ? '(awaiting transcript)'
                              : conversationItem.formatted.text ||
                                '(item sent)')}
                        </div>
                      )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === 'assistant' && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            conversationItem.formatted.text ||
                            '(truncated)'}
                        </div>
                      )}
                    {conversationItem.formatted.file && (
                      <audio
                        src={conversationItem.formatted.file.url}
                        controls
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="content-actions">
            <Toggle
              defaultValue={false}
              labels={['Manual', 'VAD']}
              values={['none', 'server_vad']}
              onChange={changeTurnEndType}
            />
            <div className="spacer" />
            {isConnected && canPushToTalk && (
              <Button
                label={isRecording ? 'Release to send' : 'Push to talk'}
                buttonStyle={isRecording ? 'alert' : 'regular'}
                disabled={!isConnected || !canPushToTalk}
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
              />
            )}
            <div className="spacer" />
            <Button
              label={isConnected ? 'Disconnect' : 'Connect'}
              iconPosition={isConnected ? 'end' : 'start'}
              icon={isConnected ? X : Zap}
              buttonStyle={isConnected ? 'regular' : 'action'}
              onClick={isConnected ? disconnectConversation : connectConversation}
            />
          </div>
        </div>

        <div className="content-right">
          <div className="content-block map">
            <div className="content-block-title">get_weather()</div>
            <div className="content-block-title bottom">
              {marker?.location || 'Not yet retrieved'}
              {!!marker?.temperature && (
                <>
                  <br />
                  🌡️ {marker.temperature.value} {marker.temperature.units}
                </>
              )}
              {!!marker?.wind_speed && (
                <>
                  {' '}
                  🍃 {marker.wind_speed.value} {marker.wind_speed.units}
                </>
              )}
            </div>
            <div className="content-block-body full">
              {coords && (
                <Map
                  center={[coords.lat, coords.lng]}
                  location={coords.location}
                />
              )}
            </div>
          </div>

          <div className="content-block kv">
            <div className="content-block-title">set_memory()</div>
            <div className="content-block-body content-kv">
              {JSON.stringify(memoryKv, null, 2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}