// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/AnalyticsPage.scss */
.analytics-page {
  padding: 2rem;
  /* Responsive layout */
}
.analytics-page h4 {
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
}
.analytics-page .MuiPaper-root {
  padding: 1rem;
  background-color: #fff;
}
.analytics-page .MuiTypography-h6 {
  margin-bottom: 1rem;
  color: #555;
}
.analytics-page .chart-container {
  position: relative;
  height: 300px;
}
.analytics-page .no-data {
  text-align: center;
  color: #777;
  font-size: 1.1rem;
}
@media (max-width: 768px) {
  .analytics-page .MuiGrid-item {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AnalyticsPage.scss"],"names":[],"mappings":"AAAA,iCAAA;AAEA;EACI,aAAA;EA6BA,sBAAA;AA5BJ;AACI;EACE,mBAAA;EACA,kBAAA;EACA,WAAA;AACN;AAEI;EACE,aAAA;EACA,sBAAA;AAAN;AAGI;EACE,mBAAA;EACA,WAAA;AADN;AAII;EACE,kBAAA;EACA,aAAA;AAFN;AAKI;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;AAHN;AAOI;EACE;IACE,WAAA;EALN;AACF","sourcesContent":["/* src/pages/AnalyticsPage.scss */\n\n.analytics-page {\n    padding: 2rem;\n  \n    h4 {\n      margin-bottom: 2rem;\n      text-align: center;\n      color: #333;\n    }\n  \n    .MuiPaper-root {\n      padding: 1rem;\n      background-color: #fff;\n    }\n  \n    .MuiTypography-h6 {\n      margin-bottom: 1rem;\n      color: #555;\n    }\n  \n    .chart-container {\n      position: relative;\n      height: 300px;\n    }\n  \n    .no-data {\n      text-align: center;\n      color: #777;\n      font-size: 1.1rem;\n    }\n  \n    /* Responsive layout */\n    @media (max-width: 768px) {\n      .MuiGrid-item {\n        width: 100%;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
