// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/LoginPage.scss */
.login-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}
.login-page h2 {
  margin-bottom: 1.5rem;
}
.login-page form {
  display: flex;
  flex-direction: column;
}
.login-page form label {
  text-align: left;
  margin-bottom: 0.5rem;
}
.login-page form input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
.login-page form button {
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #0099ff;
  color: white;
  border: none;
  cursor: pointer;
}
.login-page form button:disabled {
  background-color: #ccc;
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage.scss"],"names":[],"mappings":"AAAA,6BAAA;AACA;EACI,gBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;AACJ;AACI;EACE,qBAAA;AACN;AAEI;EACE,aAAA;EACA,sBAAA;AAAN;AAEM;EACE,gBAAA;EACA,qBAAA;AAAR;AAGM;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AADR;AAIM;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAFR;AAIQ;EACE,sBAAA;AAFV","sourcesContent":["/* src/pages/LoginPage.scss */\n.login-page {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 2rem;\n    text-align: center;\n  \n    h2 {\n      margin-bottom: 1.5rem;\n    }\n  \n    form {\n      display: flex;\n      flex-direction: column;\n  \n      label {\n        text-align: left;\n        margin-bottom: 0.5rem;\n      }\n  \n      input {\n        padding: 0.5rem;\n        margin-bottom: 1rem;\n        font-size: 1rem;\n      }\n  \n      button {\n        padding: 0.75rem;\n        font-size: 1rem;\n        background-color: #0099ff;\n        color: white;\n        border: none;\n        cursor: pointer;\n  \n        &:disabled {\n          background-color: #ccc;\n        }\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
