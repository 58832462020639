import { useState, useEffect, useRef } from 'react';
import './Toggle.scss';



type TurnDetectionValue = 'none' | 'server_vad';

type ToggleProps = {
  defaultValue?: boolean | string;
  labels?: [string, string];
  values: [TurnDetectionValue, TurnDetectionValue]; // Make this required
  onChange?: (isEnabled: boolean, value: TurnDetectionValue) => void;
};

export function Toggle({
  defaultValue = false,
  values = ['none', 'server_vad'] as [TurnDetectionValue, TurnDetectionValue],
  labels,
  onChange = () => {},
}: ToggleProps) {
  if (typeof defaultValue === 'string') {
    // Cast defaultValue to TurnDetectionValue to ensure type safety
    defaultValue = !!Math.max(0, values.indexOf(defaultValue as TurnDetectionValue));
  }

  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<boolean>(defaultValue as boolean);

  const toggleValue = () => {
    const v = !value;
    const index = +v;
    setValue(v);
    onChange(v, values[index]);
  };

  useEffect(() => {
    const leftEl = leftRef.current;
    const rightEl = rightRef.current;
    const bgEl = bgRef.current;
    if (leftEl && rightEl && bgEl) {
      if (value) {
        bgEl.style.left = rightEl.offsetLeft + 'px';
        bgEl.style.width = rightEl.offsetWidth + 'px';
      } else {
        bgEl.style.left = '';
        bgEl.style.width = leftEl.offsetWidth + 'px';
      }
    }
  }, [value]);

  return (
    <div
      data-component="Toggle"
      onClick={toggleValue}
      data-enabled={value.toString()}
    >
      {labels && (
        <div className="label left" ref={leftRef}>
          {labels[0]}
        </div>
      )}
      {labels && (
        <div className="label right" ref={rightRef}>
          {labels[1]}
        </div>
      )}
      <div className="toggle-background" ref={bgRef}></div>
    </div>
  );
}
