import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import './AuthCallbackPage.scss'

export function AuthCallbackPage() {
  const navigate = useNavigate();
  const { setSession, setUser } = useAuth();

  useEffect(() => {
    const handleMagicLink = async () => {
      if (window.location.hash && window.location.hash.includes('access_token')) {
        try {
          const hashParams = new URLSearchParams(window.location.hash.substring(1));
          const accessToken = hashParams.get('access_token');
          
          if (accessToken) {
            const { data: { session }, error } = await supabase.auth.setSession({
              access_token: accessToken,
              refresh_token: hashParams.get('refresh_token') || '',
            });
            
            if (error) {
              console.error('Error setting session:', error.message);
            } else if (session) {
              setSession(session);
              setUser(session.user);
              navigate('/dashboard');
            }
          }
        } catch (err) {
          console.error('Error processing magic link:', err);
        }
      }
    };

    handleMagicLink();
  }, [navigate, setSession, setUser]);

  return (
    <div className="auth-callback">
      <h2>Processing authentication...</h2>
      <p>Please wait while we complete your sign-in.</p>
    </div>
  );
}

export default AuthCallbackPage;