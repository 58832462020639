// src/pages/LoginPage.tsx

import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import './LoginPage.scss';

export function LoginPage() {
  const [email, setEmail] = useState('');
  const { signIn, loading } = useAuth();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await signIn(email);
    alert('Check your email for the login link!');
  };

  return (
    <div className="login-page">
      <h2>Assist Engine AI Login</h2>
      <form onSubmit={handleLogin}>
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button disabled={loading}>{loading ? 'Loading...' : 'Send Magic Link'}</button>
      </form>
    </div>
  );
}
